<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-15 09:37:08
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-31 10:58:51
-->
<template>
  <el-row :gutter="24" style="margin-top: 18px">
    <template v-for="(i, index) in searchParamList">
      <el-col :span="4" :key="i.name" v-if="i.type === 'input'">
        <v-text-field
          v-model="i.value"
          :label="i.label"
          hide-details="auto"
          outlined
          :dense="true"
          :height="32"
          :clearable="true"
        ></v-text-field>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'selectmodal'">
        <v-text-field
          v-model="i.value"
          :label="i.label" 
          hide-details="auto"
          outlined
          :dense="true"
          :height="32"
          @focus="foucusClick(i)"
          :clearable="true"
        ></v-text-field>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'date'">
        <v-menu
          :ref="i.ref"
          v-model="i.show"
          :close-on-content-click="false"
          :return-value.sync="i.value"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="i.value"
              :label="i.label"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
              :clearable="true"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="i.value" no-title range locale="zh-cn">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="timeCancal(i.ref)">取消</v-btn>
            <v-btn text color="primary" @click="timeSave(i.ref, i.value)">
              确认
            </v-btn>
          </v-date-picker>
        </v-menu>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'month'">
        <v-menu
          ref="menu"
          v-model="i.show"
          :close-on-content-click="false"
          :return-value.sync="i.value"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="i.value"
              :label="i.label"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
              :clearable="true"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="i.value"
            type="month"
            :close-on-content-click="false"
            :return-value.sync="i.value"
            transition="scale-transition"
            no-title
            scrollable 
            locale="zh-cn"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              取消
            </v-btn>
            <v-btn
              text
              color="primary" 
              @click="$refs.menu[0].save(i.value)"
            >
              确认
            </v-btn>
          </v-date-picker>
        </v-menu>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'select'">
        <v-autocomplete
          v-model="i.value"
          :items="i.option"
          :menu-props="{ bottom: true, offsetY: true }"
          :label="i.label"
          outlined
          :item-text="i.text"
          :item-value="i.id"
          :dense="true"
          :height="32"
          :clearable="true"
          clear-icon="$clear"
          hide-details="auto"
          no-data-text="暂无数据"
        ></v-autocomplete>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'tree'">
        <SelectTree
          ref="deptsys"
          :data="i.option"
          :label="i.labelName?i.labelName:'deptName'"
          :value="i.id?i.id:'deptId'"
          :labelName="i.label"
          @update:select='(value)=>change(i.name,value)'
          v-bind:select.sync="i.value" />
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'multiTree'">
        <MultiSelectTree
          ref="deptsys"
          :data="i.option"
          :label="i.labelName?i.labelName:'deptName'"
          :value="i.id?i.id:'deptId'"
          :labelName="i.label"
          v-bind:select.sync="i.value" />
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'dict'">
        <dict-select :code="i.code">
          <v-autocomplete
            v-model="i.value"
            slot-scope="props"
            :items="props.dictData"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="i.label"
            item-text="label"
            item-value="value"
            outlined
            :dense="true"
            :height="32"
            :clearable="true"
            clear-icon="$clear"
            hide-details="auto"
            :multiple="i.multiple"
            no-data-text="暂无数据"
          ></v-autocomplete>
        </dict-select>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'dictMult'">
        <dict-select :code="i.code">
          <SelectMultiple
            ref="multiple"
            v-bind:select.sync="i.value"
            :label="i.label"
            slot-scope="props"
            :items="props.dictData"
            itemText="label"
            itemValue="value"
          />
        </dict-select>
      </el-col>
      <el-col :span="4" :key="i.name" v-if="i.type === 'allSelect'">
        <SelectMultiple
          ref="multiple"
          v-bind:select.sync="i.value"
          :label="i.label"
          :items="i.option"
          :itemText="i.text"
          :itemValue="i.id"
        />
      </el-col>
    </template>
    <el-col :span="24">
      <el-button class="searchBtn" @click="searchFn">查询</el-button>
      <el-button @click="resetFn">重置</el-button>
      <el-button class="searchBtn" v-if="hasExport" @click="exportFn">导出</el-button>
      <span v-if="code !== 'dynamic' && code !== 'count'"> 
        <SearchField
          :searchAllParam="searchAllParam"
          :code="code"
          :searchParamFromApi="searchParamFromApi"
          @searchList="searchList"
        />
        <el-button type="text" @click="searchMoreFn"
          >{{ isMore ? "收起" : "展开"
          }}<i :class="isMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i
        ></el-button>
      </span>
    </el-col>
    <HqModal :width="'826'" :visible="tearchModel" title="选择" @on-cancel="cancel">
      <!-- <div style="text-align: center">{{ message }}</div> -->
      <el-row style="width: 94%; margin: 0 auto">
        <el-col :span="4">
          <v-text-field
            v-model="Searchvalue"
            hide-details="auto"
            label="老师名称"
            outlined
            :dense="true"
            :height="36"
            :clearable="true"
          ></v-text-field>
        </el-col>
        <el-col :span="12">
          <el-button class="searchBtn" style="marginLeft: 20px" @click="searchFnteach">查询</el-button>
        </el-col>
        <el-col>
          <el-table
            ref="multipleTable"
            :data="tableTearchData"
            tooltip-effect="dark"
            style="width: 100%"
            height="397"
            border
            stripe
            size="mini"
            highlight-current-row
            v-loading="listTearchLoading"
            element-loading-text="拼命加载中"
            :header-cell-style="{ background: '#e5f2ff' }"
            @row-click="clickRow">
            <el-table-column :show-overflow-tooltip="true"
              label="ID"
              prop="id"
              
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
              label="老师名称"
              prop="nickName"
              
            ></el-table-column>
          </el-table>
          <div class="page_bottom">
            <el-pagination
              background
              @size-change="sizeChange"
              @current-change="currentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="pageParam.curPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageParam.pageSize"
              :total="pageParam.total"
            />
          </div>
        </el-col>
      </el-row>
      <template #footer>
        <el-button
          type="primary"
          @click="searchTeachOk()"
          :loading="tearchloading"
          >确定</el-button
        >
        <el-button
          type="error"
          @click="cancel"
          style="margin-left: 8px"
          >取消</el-button
        >
      </template>
    </HqModal>
  </el-row>
</template>
<script>
import SearchField from "components/search-field";
import SelectTree from "components/select-tree";
import dictSelect from "components/dictSelect";
import SelectMultiple from "components/select-multiple";
import MultiSelectTree from "components/multiSelect-tree";
import { mapGetters } from "vuex";

export default {
  name: "search",
  components: {
    SearchField,
    SelectTree,
    dictSelect,
    SelectMultiple,
    MultiSelectTree,
  },
  props: {
    hasExport:{
      type:Boolean,
      default:false
    },
    searchAllParam: {
      type: Array,
    },
    searchParamList: {
      type: Array,
    },
    code: {
      type: String,
    },
    searchParamFromApi: {
      type: Array,
    },
    searchTearchList: {
      type: Object
    }
  },
  data() {
    return {
      // 更多条件
      isMore: false,
      menu: false,
      tearchloading: false,
      tearchModel: false,
      listTearchLoading: false,
      currCol:null,
      tableTearchData: [],
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      Searchvalue: "",
      searchtemp: {},//判断是点击哪个老师
    }
  },
  methods: {
    change(name,value){
      this.$emit('change',{name,value})
    },
    timeSave(ref, value) {
      if(!(!!value[0] && !!value[1])) {
        return false
      }
      let time = [];
      if(new Date(value[0]).getTime() < new Date(value[1]).getTime()) {
        time = value
      } else {
        time.push(value[1])
        time.push(value[0])
      }
      this.$refs[ref][0].save(time)
    },
    timeCancal(ref) {
      this.$refs[ref][0].save()
    },
    // 查询
    searchFn() {
      this.$emit("search");
    },
    // 重置
    resetFn() {
      this.$emit("reset");
      this.$refs.deptsys && this.$refs.deptsys.map((item, index) => {
        this.$refs.deptsys[index].init()
      })
      this.$refs.multiple && this.$refs.multiple.map((item, index) => {
        this.$refs.multiple[index].init()
      })
    },
    // 导出
    exportFn(){
      this.$emit("exportData");
    },
    // 自定义查询确认
    searchList() {
      this.$emit("searchList");
    },
    // 更多条件
    searchMoreFn() {
      this.isMore = !this.isMore;
      this.$emit("searchMore", this.isMore);
    },
    // 聚焦事件
    foucusClick(val) {
      this.searchtemp = val
      console.log('聚焦了',val);
      this.tearchModel = true
      this.tableTearchData = this.searchTearchList.data
      this.pageParam.pageNum = this.searchTearchList.pageInfo.curpage
      this.pageParam.total = this.searchTearchList.pageInfo.total
      console.log('this.tableTearchData', this.tableTearchData);
    },
    // 确认弹窗按钮
    searchTeachOk() {
      console.log('searchParamList', this.searchParamList);
      this.searchParamList.map((item) => {
        // 招生老师
        if(this.searchtemp.name == "teacherRecruitNCid") {
          if (item.name == 'teacherRecruitNCid') {
            item.value = this.currCol.nickName
            item.id = this.currCol.id
          }
        } else if (this.searchtemp.name == "teacherSalesNCid") {
          // 销售老师
          if (item.name == 'teacherSalesNCid') {
            item.value = this.currCol.nickName
            item.id = this.currCol.id
          }
        } else if (this.searchtemp.name == "teacherRecommandNCid") {
          // 转介绍老师
          if (item.name == 'teacherRecommandNCid') {
            item.value = this.currCol.nickName
            item.id = this.currCol.id
          }
        } else if (this.searchtemp.name == "changeBeforeNcId") {
          // 变更前老师
          if (item.name == 'changeBeforeNcId') {
            item.value = this.currCol.nickName
            item.id = this.currCol.id
          }
        } else if (this.searchtemp.name == "changeAfterNcId") {
          // 变更后老师
          if (item.name == 'changeAfterNcId') {
            item.value = this.currCol.nickName
            item.id = this.currCol.id
          }
        }
      })
      this.tearchModel = false
    },
    // 弹窗的查询
    searchFnteach() {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = 10
      this._getFindAll()
    },
    // 取消弹窗按钮
    cancel() {
      this.tearchModel = false
    },
    // 点击行事件
    clickRow(row) {
      this.currCol = row;
    },
    sizeChange (val) {
       this.pageParam.pageSize = val;
       this._getFindAll()
    },
    currentChange(val) {
       this.pageParam.pageNum = val;
       this._getFindAll()
    },
    // 查询后的函数
    async _getFindAll() {
      let params = {
        pageSize: this.pageParam.pageSize,
        curPage: this.pageParam.pageNum ? this.pageParam.pageNum : 1,
        nickName: this.Searchvalue
      }
      let data =  await this.$fetch("getTeacherFindAll", params)
      console.log('获取三种老师', data)
      this.tableTearchData = data.data
      this.pageParam.pageNum = data.pageInfo.curpage
      this.pageParam.pageSize = 20
      this.pageParam.total = data.pageInfo.total
    },
    toggle (item) {
      this.$nextTick(() => {
        if (item.value.length === item.option.length) {
          item.value = []
        } else {
          item.value = item.option.slice().map(i => i.id)
        }
      })
    },
  },
  computed: {
    ...mapGetters(["loginUser"]),
    icon () {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
};
</script>

<style lang="scss" scoped>
  .confirm_box {
    width: 828px !important;
  }
  .el-col {
    margin-bottom: 12px !important;
  }
  .el-col-4 {
    width: 20%;
  }
  .el-button {
    border-radius: 0px !important;
  }
  .searchBtn {
    background-color: #337AFF;
    color: #fff;
    &:hover {
      background-color: #5c95ff;
    }
  }
</style>
